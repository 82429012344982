import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bc752dfe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contract-login" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "container-header" }
const _hoisted_4 = { class: "tagline" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "contract-message" }
const _hoisted_7 = { class: "message" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "message" }
const _hoisted_10 = { class: "message" }
const _hoisted_11 = { class: "strike" }
const _hoisted_12 = { class: "or-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_BorderButton = _resolveComponent("BorderButton")!
  const _component_TextButton = _resolveComponent("TextButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("sign.loginTaglineText")), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("sign.loginTitleText")), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, [
          _createElementVNode("span", {
            class: "email",
            innerHTML: _ctx.$t('sign.loginEmail', { email: _ctx.details.email })
          }, null, 8, _hoisted_8),
          _createTextVNode(_toDisplayString(_ctx.$t("sign.loginFirstInfoLine")), 1)
        ]),
        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("sign.loginSecondInfoLine")), 1),
        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t("sign.loginThirdInfoLine")), 1)
      ]),
      _createVNode(_component_FlatButton, {
        class: "guest-login-btn mt-5",
        text: _ctx.$t('sign.loginGuest'),
        onOnClick: _ctx.guestLogin
      }, null, 8, ["text", "onOnClick"]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("login.loginOr")), 1)
      ]),
      _createVNode(_component_BorderButton, {
        onOnClick: _ctx.login,
        text: _ctx.$t('sign.loginButton'),
        class: "login-button"
      }, null, 8, ["onOnClick", "text"]),
      _createVNode(_component_TextButton, {
        onOnClick: _ctx.goHelp,
        text: _ctx.$t('sign.loginHowToUse'),
        class: "text-button"
      }, null, 8, ["onOnClick", "text"])
    ])
  ]))
}