
// @ is an alias to /src
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import BorderButton from "@/components/atomics/BorderButton.vue";
import storage from "@/lib/storage/storageHelper";
import useSignFunctions from "@/lib/compositional-logic/useSignFunctions";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ContractLogin",
  components: {
    FlatButton,
    TextButton,
    BorderButton
  },
  setup() {
    const state = reactive({
      code: "",
      details: {} as any
    });

    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const { getAccessCode } = useSignFunctions();

    const localStorage = storage.getLocalStorage();
    const sessionId = localStorage.get("sessionId");


    
    const login = () => {
      router.push({
        name: "Login",
        params: {
          isContractLogin: String(true),
          requireAccessCode: String(route.query.requireAccessCode),
        },
      });
    };

    const guestLogin = () => {
      router.push("/sign/terms");
    };

    const goHelp = () => {
      window.open("https://help.digitalsign.jp/", "_blank");
    };

    onMounted(() => {

      try {
        state.code = route.query.code as string;
        const isRequiredAccessCode = route.query.requireAccessCode === "true";
        const decode = Buffer.from(state.code, "base64").toString();

        state.details = JSON.parse(decode);
        const userType = localStorage.get("userType");

        localStorage.add("guestCode", state.code);

        if (sessionId && userType == "user") {
          const accessCode = getAccessCode(state.details.contractId);
          if (isRequiredAccessCode && !accessCode)
            router.push({
              name: "Access Code",
            });
          else router.push(`/sign/details?contractId=${state.details.contractId}`);
        }
      } catch (e: any) {
        router.push({
          name: "ErrorPage",
          query: {
            error: t("errors.wrongGuestCode"),
          },
        });
      }
      

    })
    

    return {
      ...toRefs(state),
      login,
      guestLogin,
      goHelp,
    };
  },
});
